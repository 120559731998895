import React from 'react';
import { collection, getDocs, doc, deleteDoc, addDoc } from 'firebase/firestore';
import { useState, useEffect } from 'react';
import { Card, Button, Container, Row, Col, Modal, Image } from 'react-bootstrap';
import '../App.css';
import { db, auth } from '../firebase-config';
import { onAuthStateChanged } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';


function MyListProp() {
    const [property, setProps] = useState([]);
    const [realEmail, setNewEmail] = useState("");
    const history = useNavigate();
    // const deletedCollections = collection(db, "deletedProperties");

    useEffect(() => {
        onAuthStateChanged(auth, (user) => {
            if (user) {
                sessionStorage.setItem('email', user.email.replace('@gmail.com', ''));
                let delEmail = sessionStorage.getItem('email');
                setNewEmail(delEmail);

            } else {
                console.log('not logged in');
            }
        });
    }, [])

    async function handleChange(param) {
        // console.log('deleted', param.address);
        await addDoc(collection(db, "deleted"), {
            param: param.address + '    ' + param.realtorEmail,
        });
        await deleteDoc(doc(db, realEmail, param.id));
        window.location.reload();
    };


    const handleShare = async () => {
        console.log('sharing');
        navigator.share({
            title: 'Names Page',
            url: `https://restateprops.com/r/${emailGet}`
        }).then(() => {
            console.log('shared');
        }).catch(console.error());
    }

    const goBack = async () => {
        history("/dashboard");
    }

    useEffect(() => {
        const getProps = async () => {
            const email = sessionStorage.getItem('email');
            const propsCollectionsRef = collection(db, email);
            const data = await getDocs(propsCollectionsRef);
            setProps(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
        };
        getProps();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const emailGet = sessionStorage.getItem('email');
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    return (
        <Container>
            <Row>
                <Col lg="8">
                    <h4>Send this link to you clients to have them look at your properties</h4>
                    <h6>https://restateprops.com/r/{emailGet}</h6>
                </Col>
                <Col lg='2'>
                    <Button variant="success" onClick={() => handleShare()}>Share Link</Button>
                </Col>
                <Col lg='2'>
                    <Button variant="primary" onClick={() => goBack()}>Go Back</Button>
                </Col>
            </Row>
            <div>
                {
                    property.map((theprop) => {
                        return (
                            <>
                                <div className="container">
                                    <br></br>
                                    <div className="row justify-content-center">

                                        <Card border="success" style={{ width: '70rem', flexDirection: 'row' }}>
                                            <Card.Img onClick={handleShow} variant="top" src={theprop.photo} alt="house prop" style={{ width: '70vh', margin: '10px' }} />
                                            <Modal show={show} onHide={handleClose}>
                                                <Modal.Header closeButton>
                                                    <Modal.Title>{theprop.address} Pictures</Modal.Title>
                                                </Modal.Header>
                                                <Modal.Body><Image src={theprop.photo[0]}></Image></Modal.Body>
                                                <Modal.Footer>
                                                    <Button variant="secondary" onClick={handleClose}>
                                                        Close
                                                    </Button>
                                                </Modal.Footer>
                                            </Modal>
                                            <Card.Body>

                                                <Card.Title><h2>{theprop.address}</h2></Card.Title>
                                                <Card.Text>
                                                    {theprop.city + ", " + theprop.state + " " + theprop.zipcode}
                                                </Card.Text>
                                                <Card.Text>
                                                    Description:  {theprop.description}
                                                </Card.Text>
                                                <Card.Text>
                                                    Square Feet:  {theprop.sqFeet}
                                                </Card.Text>
                                                <Card.Text>
                                                    Number of Bedrooms: {theprop.numberOfBed} <br></br> Number of Bathrooms: {theprop.numberOfBath}
                                                </Card.Text>
                                                <Card.Text>
                                                    Parking:  {theprop.parking}
                                                </Card.Text>
                                                <Card.Text>
                                                    Transaction Type:  {theprop.transactionType} <br></br> Price: ${theprop.price}
                                                </Card.Text>
                                                <Card.Text>
                                                    Realtor Name: {theprop.realtorName}
                                                </Card.Text>

                                                <a href={"https://mail.google.com/mail/?view=cm&fs=1&tf=1&to=" + theprop.realtorEmail + "&su=I am interested in " + theprop.address + " " + theprop.zipcode + "&body=Please contact me, I want more info about " + theprop.address}>Contact {theprop.realtorName}</a>

                                            </Card.Body>
                                            <div>
                                                <br></br>
                                                <Button variant="primary" onClick={() => handleChange(theprop)}>Delete Property</Button>
                                            </div>
                                        </Card>
                                    </div>
                                    <br></br>
                                </div>
                            </>
                        );
                    })
                }
            </div>
        </Container>
    );



}

export default MyListProp;