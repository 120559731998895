import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Navbar, Container, Nav, Col } from 'react-bootstrap';
import { auth, provider } from '../firebase-config';
import { onAuthStateChanged, signInWithPopup, GoogleAuthProvider } from 'firebase/auth';
import '../App.css';

export function refreshPage() {
  window.location.reload(true);
}

export default function NavBar() {
  const [loggedState, setLogState] = useState("");
  const history = useNavigate();

  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      if (user) {
        // console.log(user);
        setLogState('Dashboard');
      } else {
        // console.log('not logged in');
        setLogState("Realtor Login/SignUp");
      }

    });
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const signIn = () => {
    if (loggedState === 'Dashboard') {
      history('dashboard');
    } else {
      popup();
    }
  }

  const popup = () => {
    signInWithPopup(auth, provider)
      .then((result) => {
        const credential = GoogleAuthProvider.credentialFromResult(result);
        // eslint-disable-next-line
        const token = credential.accessToken;

        history('/dashboard');
      }).catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        const email = error.email;
        const credential = GoogleAuthProvider.credentialFromError(error);
        console.log(errorCode, " ", errorMessage, " email used: ", email, " cred: ", credential);

      });
  }

  return (
    <Navbar className='navbar' bg="light" expand="lg">
      <Container>
        <Col lg='10'>
          <Navbar.Brand className='textColor' href="/">Real Estate Properties<span style={{fontSize:10}}> Beta</span></Navbar.Brand>
        </Col>
        <Col lg='2'>

          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto">
              <Nav.Link className='textColor' onClick={signIn}>{loggedState}</Nav.Link>
            </Nav>
            <Nav>
              <Nav.Link className='textColor' href='/contact'>Contact</Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Col>
      </Container>
    </Navbar>
  );
}
