import React from 'react';
import { Container, Nav, NavItem, NavLink } from 'react-bootstrap';

function Footer(/*props*/) {
  return (
    <footer className='footer'>
      <Container>
        <div style={{ display: "flex", justifyContent: "center", alignItems: "center", flexDirection: 'column' }}>
          <Nav>
            <NavItem>
              <NavLink className='footerNav' href='https://bkofie.com'>Privacy Policy</NavLink>
            </NavItem>
            <NavItem>
              <NavLink className='footerNav' href='https://bkofie.com'>Terms & Conditions</NavLink>
            </NavItem>
            <NavItem>
              <NavLink className='footerNav' href='https://buy.stripe.com/bIY9B3aNE0qA9ZmeUU'>$10 Donations</NavLink>
            </NavItem>
          </Nav>

          <div className="text-center small copyright">
            © Webdevry 2022
          </div>
        </div>
      </Container>
    </footer>
  );
}

export default Footer;