import React from 'react';
import '../App.css';
import 'bootstrap/dist/css/bootstrap.min.css';


function page404() {
  
    return (
        <div>
            <p>404</p>
            <p>Oops this property isn't for sale</p>
        </div>
    );

}

export default page404;