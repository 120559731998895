import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route, Routes} from "react-router-dom";
import NavBar  from './pages/Nav';
import AddProp from './pages/AddProp';
import Home from './pages/Home';
import ListProp from './pages/ListProp';
import Page404 from './pages/page404';
import Login from './pages/Login';
import Dashboard from './pages/Dashboard';
import Realtors from './pages/Realtors';
import MyListProp from './pages/MyListProp';
import Footer from './pages/Footer';
import Contact from './pages/Contact';

const Routing = () => {
  return(
    <Router>
      <NavBar/>
      <Routes>
        <Route exact path="/" element={<Home/>} />
        <Route path="/add-properties" element={<AddProp/>} />
        <Route path="/list-properties" element={<ListProp/>} />
        <Route path="/login" element={<Login/>} />
        <Route path="/dashboard" element={<Dashboard/>} />
        <Route path="/r/:realtor" element={<Realtors/>} />
        <Route path="/my-list" element={<MyListProp/>} />
        <Route path="/contact" element={<Contact/>} />
        <Route element={<Page404/>} />
      </Routes>
      <Footer/>
    </Router>
  )
}


ReactDOM.render(
  <React.StrictMode>
    <Routing/>
  </React.StrictMode>,
  document.getElementById('root')
);
