import React, { useState, useEffect } from "react";
import '../App.css';
import {
    Col,
    Container,
    Row,
    Form,
    Button,
    InputGroup,
    Accordion,
    Card
} from "react-bootstrap";
import { db, analytics } from '../firebase-config';
import { logEvent } from "firebase/analytics";
import { collection, query, where, getDocs } from 'firebase/firestore';
import 'bootstrap/dist/css/bootstrap.min.css';


function Header() {
    const [queries, setQuery] = useState("");
    const [property, setProps] = useState([]);

    async function searchProp(e) {
        e.preventDefault();
        const collectionRef = collection(db, "abodeUsers");
        const q = query(collectionRef, where("zipcode", "==", Number(queries)));
        const querySnapshot = await getDocs(q);
   
        querySnapshot.forEach((doc) => {
            setProps(querySnapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
        });
    }

    useEffect(() => {
        logEvent(analytics, 'screen_view', {
            firebase_screen: 'home',
            firebase_screen_class: 'home1'
        })
      }, [])

    return (
        <>
            <header className="header">
                <Container fluid className="p-3">
                    <Row>
                        <Form onSubmit={searchProp}>
                            <Form.Group>
                                <Form.Label>Search Property List</Form.Label>

                                <Col xs={6}>

                                    <InputGroup>

                                        <Form.Control placeholder="Enter Zip Code" onChange={(event) => {
                                            setQuery(event.target.value);
                                        }} />
                                        <Button onClick={searchProp} className="btn btn-warning text-right">
                                            <svg width="15px" height="20px">
                                                <path d="M11.618 9.897l4.224 4.212c.092.09.1.23.02.312l-1.464 1.46c-.08.08-.222.072-.314-.02L9.868 11.66M6.486 10.9c-2.42 0-4.38-1.955-4.38-4.367 0-2.413 1.96-4.37 4.38-4.37s4.38 1.957 4.38 4.37c0 2.412-1.96 4.368-4.38 4.368m0-10.834C2.904.066 0 2.96 0 6.533 0 10.105 2.904 13 6.486 13s6.487-2.895 6.487-6.467c0-3.572-2.905-6.467-6.487-6.467 "></path>
                                            </svg>
                                        </Button>

                                    </InputGroup>
                                </Col>

                            </Form.Group>
                        </Form>
                    </Row>
                </Container>
            </header>

            <section className="headerSearch">
                <div></div>
                {
                    property.map((theprop) => {

                        return (
                            <>
                                <div className="container">
                                    <Container>
                                        <Row>
                                        <Card border="success" style={{ width: '70rem', flexDirection: 'row' }}>
                                            <Card.Img variant="top" src={theprop.photo} alt="house prop" style={{ width: '70vh', margin: '10px' }} />
                      
                                            <Card.Body>

                                                <Card.Title><h2>{theprop.address}</h2></Card.Title>
                                                <Card.Text>
                                                    {theprop.city + ", " + theprop.state + " " + theprop.zipcode}
                                                </Card.Text>
                                                <Card.Text>
                                                    Description:  {theprop.description}
                                                </Card.Text>
                                                <Card.Text>
                                                    Square Feet:  {theprop.sqFeet}
                                                </Card.Text>
                                                <Card.Text>
                                                    Number of Bedrooms: {theprop.numberOfBed} <br></br> Number of Bathrooms: {theprop.numberOfBath}
                                                </Card.Text>
                                                <Card.Text>
                                                    Parking:  {theprop.parking}
                                                </Card.Text>
                                                <Card.Text>
                                                    Transaction Type:  {theprop.transactionType} <br></br> Price: ${theprop.price}
                                                </Card.Text>
                                                <Card.Text>
                                                    Realtor Name: {theprop.realtorName}
                                                </Card.Text>

                                                <a href={"https://mail.google.com/mail/?view=cm&fs=1&tf=1&to=" + theprop.realtorEmail + "&su=I am interested in " + theprop.address + " " + theprop.zipcode + "&body=Please contact me, I want more info about " + theprop.address}>Contact {theprop.realtorName}</a>

                                            </Card.Body>
                                        </Card>
                                        </Row>
                                    </Container>
                                </div>
                            </>
                        );
                    })
                }
            </section>
        </>
    );
}

function Home() {
    return (
        <>
            <Header />
            <Container fluid>
                <Row>
                    <Accordion flush>
                        <Accordion.Item eventKey="0">
                            <Accordion.Header > Why use this website? </Accordion.Header>
                            <Accordion.Body>
                                We don't ask for commission rates, I guess.
                            </Accordion.Body >
                        </Accordion.Item>
                        <Accordion.Item eventKey="1" >
                            <Accordion.Header > How do we use this website?</Accordion.Header>
                            <Accordion.Body >
                                Realtors login, add properties and share the page with their clients. Clients can then get more info from the realtor by email.
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="2" >
                            <Accordion.Header >Are realtors verified?</Accordion.Header>
                            <Accordion.Body >
                                All realtors go through a screening process to weed out the scammers.
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>
                </Row>
            </Container>
        </>

    );
}

export default Home;