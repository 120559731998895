import React, { useState, useEffect } from 'react';
import { collection, getDocs } from '@firebase/firestore';
import { Card, Container, Row, Col } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { db } from '../firebase-config';
import '../App.css';
import { useParams } from 'react-router-dom';


// function Carousels() {
//     const [property, setProps] = useState([]);
//     var indexe = 0;

//     useEffect(() => {
//         const getProps = async () => {
//             const propsCollectionsRef = collection(db, realtors);
//             const data = await getDocs(propsCollectionsRef);
//             setProps(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
//         };
//         getProps();
//     }, [realtors]); // eslint-disable-line react-hooks/exhaustive-deps

//     return (
//         <>
//             <Carousel variant="dark">
//                 {property.map((index) => {
//                     indexe++;
//                     return (
//                         <Carousel.Item>

//                             <Image
//                                 className='d-block w-100 h-100'
//                                 fluid='true'
//                                 src={index.photo}
//                                 alt={`${indexe} slide`}
//                             />

//                         </Carousel.Item>
//                     );
//                 })
//                 }
//             </Carousel>

//         </>
//     );
// }

function Realtors({ match }) {

    let { realtor } = useParams();


    const [property, setProps] = useState([]);


    useEffect(() => {
        const getProps = async () => {
            const propsCollectionsRef = collection(db, realtor);
            const data = await getDocs(propsCollectionsRef);
            setProps(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
        };
        getProps();
    }, [realtor]); // eslint-disable-line react-hooks/exhaustive-deps

    let name;

    return (
        <>
            <div>
                {property.map((prop) => {
                    return(<p hidden>{name = prop.realtorName}</p>);
                })}
                <h1>{name}'s Properties</h1>
            </div>
            {
                property.map((theprop) => {
                    return (
                        <>
                            <Container>
                                <Row className="row justify-content-center">
                                    <Col className="row justify-content-center" lg={12}>
                                    <Card border="success" className='headerSearch' style={{ width: '70rem', flexDirection: 'row' }}>

                                        {/* <Card className='headerSearch' border="success"> */}
                                            <img src={theprop.photo} alt="house prop" />
                                            
                                            {/* <Carousels realtors={realtor} /> */}
                                            <Card.Body>
                                                <Card.Title><h2>{theprop.address}</h2></Card.Title>
                                                <Card.Text>
                                                    {theprop.city + ", " + theprop.state + " " + theprop.zipcode}
                                                </Card.Text>
                                                <Card.Text>
                                                    Description:  {theprop.description}
                                                </Card.Text>
                                                <Card.Text>
                                                    Number of Bedrooms: {theprop.numberOfBed} <br></br> Number of Bathrooms: {theprop.numberOfBath}
                                                </Card.Text>
                                                <Card.Text>
                                                    Realtor Name: {theprop.realtorName}
                                                </Card.Text>
                                                <a href={"https://mail.google.com/mail/?view=cm&fs=1&tf=1&to=" + theprop.realtorEmail + "&su=I am interested in " + theprop.address + " " + theprop.zipcode + "&body=Please contact me, I want more info about " + theprop.address}>Contact Me</a>
                                            </Card.Body>
                                        </Card>
                                    </Col>

                                </Row>
                            </Container>

                        </>
                    );
                })
            }
        </>
    );
};

export default Realtors;