import React from 'react';
import { collection, getDocs } from '@firebase/firestore';
import { useState, useEffect } from 'react';
import { Card } from 'react-bootstrap';
import '../App.css';
import { db } from '../firebase-config';


function ListProp() {
  const [property, setProps] = useState([]);
  const propsCollectionsRef = collection(db, "abodeUsers");
  // const theSubjet = "I am inquiring about this address";

  useEffect(() => {

    const getProps = async () => {
      const data = await getDocs(propsCollectionsRef);
      setProps(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
    };

    getProps();

  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div>
      {
        property.map((theprop) => {

          return (
            <>
              <div className="container">
                <br></br>
                <div className="row justify-content-center">
                  <Card border="success" style={{ width: '70rem', flexDirection: 'row' }}>
                    <Card.Img variant="top" src={theprop.photo} alt="house prop" style={{ width: '70vh', margin: '10px' }} />
                    <Card.Body>

                      <Card.Title><h2>{theprop.address}</h2></Card.Title>
                      <Card.Text>
                        {theprop.city + ", " + theprop.state + " " + theprop.zipcode}
                      </Card.Text>
                      <Card.Text>
                        Description:  {theprop.description}
                      </Card.Text>
                      <Card.Text>
                        Number of Bedrooms: {theprop.numberOfBed} <p></p> Number of Bathrooms: {theprop.numberOfBath}
                      </Card.Text>
                      <Card.Text>
                        Realtor Name: {theprop.realtorName}
                      </Card.Text>

                      <a href={"https://mail.google.com/mail/?view=cm&fs=1&tf=1&to=" + theprop.realtorEmail + "&su=I am interested in " + theprop.address + " " + theprop.zipcode + "&body=Please contact me, I want more info about " + theprop.address}>Contact {theprop.realtorName}</a>
                    </Card.Body>
                  </Card>
                </div>
                <br></br>
              </div>
            </>
          );
        })
      }
    </div>
  );



}

export default ListProp;