import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { auth } from "../firebase-config.js";
import { Button, Container, Col, Row } from 'react-bootstrap';
import { signOut, onAuthStateChanged } from "firebase/auth";


const Dashboard = () => {
    const [user, setUser] = useState('');
    const history = useNavigate();

    useEffect(() => {
        onAuthStateChanged(auth, (user) => {
          if (user) {
            setUser(user);
          } else {
            console.log('not logged in');
            history('/login');
          }
        });
      }, []) // eslint-disable-line react-hooks/exhaustive-deps
    

    const signedOut = async () => {
        signOut(auth).then(() => {
            // Sign-out successful.
            console.log("Sign out successful");
        }).catch((error) => {
            // An error happened.
        });

        history("/");
    }

    return (
      <Container>
        <Row>
          <Col lg='9'>
            <h1>{user.displayName}'s Dashboard</h1>
            </Col>
            <Col lg='2'>
            <Button onClick={signedOut} variant="danger">Sign out</Button>
            </Col>
            </Row>
            <p>You can add and delete properties here.</p>
            <Row>
              <Col lg='4'>
              <Button href='/my-list'>View Your Property List</Button>

              </Col>
            <Col lg='4'>
            <Button href='/add-properties'>Add a Property</Button>

            </Col>
            </Row>
        </Container>
    );
};

export default Dashboard;