import React, { useState, useEffect } from 'react';
import { addDoc, collection } from '@firebase/firestore';
import { useNavigate } from "react-router-dom";
import { ref, uploadBytes, getDownloadURL } from '@firebase/storage';
import { Button, Form, Row, Col, Container } from 'react-bootstrap';
import { db, storage, auth } from '../firebase-config';
import { onAuthStateChanged } from '@firebase/auth';


export default function AddProp() {
  const history = useNavigate();

  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      if (user) {
        setNewEmail(user.email);
        setNewName(user.displayName);

      } else {
        console.log('not logged in');
      }
    });
  }, [])


  const handleChange = e => {
    for (let i = 0; i < e.target.files.length; i++) {
      const newImage = e.target.files[i];
      newImage["id"] = Math.random();
      // if (e.target.files[0]) {
      const photoRef = ref(storage, `images/${realEmail}/${newImage.name}`);
      uploadBytes(photoRef, e.target.files[i]).then((snapshot) => {

        getDownloadURL(photoRef).then((url) => {
          // sessionStorage.setItem('theurl', url);
          // console.log("the download url is ", url);
          urlpush.push(url);
          sessionStorage.setItem('thearray', JSON.stringify(urlpush));

          // console.log("seturl", urlpush);
        }).catch((error) => {
          console.log('something wen wrong', error, error.message);
        });
      })

      // };
    }
  }

  const urlpush = [];
  const photoUrl = [];
  const [newAddress, setNewAddress] = useState("");
  const [newState, setNewState] = useState("");
  const [newCity, setNewCity] = useState("");
  const [realEmail, setNewEmail] = useState("");
  const [realName, setNewName] = useState("");
  const [numBed, setNewBed] = useState(0);
  const [numBath, setNewBath] = useState(0);
  const [newDescript, setNewDescription] = useState("");
  const [newZip, setNewZip] = useState(0);
  const propsCollections = collection(db, "abodeUsers");
  const [propType, setPropType] = useState("");
  // const [photo, setPhoto] = useState([]);
  // const [photo, setPhoto] = useState("");

  const [transactionType, setTransactionType] = useState("");
  const [newSqFeet, setSqFeet] = useState("");
  const [newParking, setParking] = useState("");
  const [newPrice, setPrice] = useState("");


  const addProperty = async () => {
    const url = JSON.parse(sessionStorage.getItem('thearray'));

    for (let i = 0; i < url.length; i++) {
      // setPhoto(url[i]);
      photoUrl.push(url[i]);
    }
    addInfo();
  };

  const goBack = async () => {
    history("/dashboard");
  }

  const addInfo = async () => {
    await addDoc(propsCollections, {
      propType: propType,
      city: newCity,
      state: newState,
      photo: photoUrl,
      address: newAddress,
      zipcode: Number(newZip),
      description: newDescript,
      numberOfBed: numBed,
      numberOfBath: numBath,
      realtorName: realName,
      realtorEmail: realEmail,
      transactionType: transactionType,
      price: newPrice,
      sqFeet: newSqFeet,
      parking: newParking
    });
    addUserProps();

  };

  const addUserProps = async () => {
    const email = realEmail.replace('@gmail.com', '');
    const userPropsCollections = collection(db, email);
    await addDoc(userPropsCollections, {
      propType: propType,
      city: newCity,
      state: newState,
      photo: photoUrl,
      address: newAddress,
      zipcode: Number(newZip),
      description: newDescript,
      numberOfBed: numBed,
      numberOfBath: numBath,
      realtorName: realName,
      realtorEmail: realEmail,
      transactionType: transactionType,
      price: newPrice,
      sqFeet: newSqFeet,
      parking: newParking
    });
    window.location.reload();
  };

  return (
    <Container>
      <br></br>

      <div className="container justify-content-center col-10">
        <Row>
          <Form>
            <Form.Group className="mb-3" controlId="realtorAddress">
              <Form.Label>Property Address</Form.Label>
              <Form.Control type="text" placeholder="123 Example" onChange={(event) => {
                setNewAddress(event.target.value);
              }} />
            </Form.Group>

            <Row className="mb-3">

              <Form.Group as={Col} controlId="realtorCity">
                <Form.Label>City</Form.Label>
                <Form.Control type="text" placeholder="Some City" onChange={(event) => {
                  setNewCity(event.target.value);
                }} />
              </Form.Group>


              <Form.Group as={Col} controlId="realtorState">
                <Form.Label>State</Form.Label>
                <Form.Control type="text" placeholder="NY" onChange={(event) => {
                  setNewState(event.target.value);
                }} />
              </Form.Group>

              <Form.Group as={Col} controlId="realtorZip">
                <Form.Label>Zip Code</Form.Label>
                <Form.Control type="number" placeholder="00000" onChange={(event) => {
                  setNewZip(event.target.value);
                }} />
              </Form.Group>

            </Row>

            <Form.Group>
              <p>Property Type</p>
              {['radio'].map((type) => (
                <div key={`inline-${type}`} className="mb-3">
                  <Form.Check
                    inline
                    label="House"
                    name="group1"
                    type={type}
                    id={`inline-${type}-1`}
                    onChange={(event) => {
                      setPropType('House');

                    }}
                  />
                  <Form.Check
                    inline
                    label="Multi-Family"
                    name="group1"
                    type={type}
                    id={`inline-${type}-2`}
                    onChange={(event) => {
                      setPropType('Multi-Family');

                    }}
                  />
                  <Form.Check
                    inline
                    label="Condo"
                    name="group1"
                    type={type}
                    id={`inline-${type}-2`}
                    onChange={(event) => {
                      setPropType('Condo');

                    }}
                  />
                  <Form.Check
                    inline
                    label="Townhouse"
                    name="group1"
                    type={type}
                    id={`inline-${type}-2`}
                    onChange={(event) => {
                      setPropType('Townhouse');
                    }}
                  />

                </div>
              ))}
            </Form.Group>

            <Form.Group>
              <p>Transaction Type</p>
              {['radio'].map((type) => (
                <div key={`inline-${type}`} className="mb-3">
                  <Form.Check
                    inline
                    label="For Sale"
                    name="group2"
                    type={type}
                    id={`inline-${type}-1`}
                    onChange={(event) => {
                      setTransactionType('For Sale');

                    }}
                  />
                  <Form.Check
                    inline
                    label="For Rent"
                    name="group2"
                    type={type}
                    id={`inline-${type}-2`}
                    onChange={(event) => {
                      setTransactionType('For Rent');

                    }}
                  />
                </div>
              ))}
            </Form.Group>

            <Row className="mb-3">

              <Form.Group as={Col} controlId="realtorbed">
                <Form.Label>Bedrooms</Form.Label>
                <Form.Control type="number" placeholder="Number of bedrooms" onChange={(event) => {
                  setNewBed(event.target.value);
                }} />
              </Form.Group>

              <Form.Group as={Col} controlId="realtorbath">
                <Form.Label>Bathrooms</Form.Label>
                <Form.Control type="number" placeholder="Number of bathrooms" onChange={(event) => {
                  setNewBath(event.target.value);
                }} />
              </Form.Group>

              <Form.Group as={Col} controlId="realtorsq">
                <Form.Label>Sq Feet</Form.Label>
                <Form.Control type="number" placeholder="Number of square feet" onChange={(event) => {
                  setSqFeet(event.target.value);
                }} />
              </Form.Group>

              <Form.Group as={Col} controlId="realtorpark">
                <Form.Label>Parking</Form.Label>
                <Form.Control type="number" placeholder="Number of parking spots" onChange={(event) => {
                  setParking(event.target.value);
                }} />
              </Form.Group>
            </Row>

            <Form.Group className="mb-3" controlId="descript">
              <Form.Label>Description</Form.Label>
              <Form.Control as="textarea" rows={3} placeholder="Kitchen, Bathroom, Room, Outdoor and Miscellaneous details" onChange={(event) => {
                setNewDescription(event.target.value);
              }} />
            </Form.Group>

            <Row>
              <Col lg='3'>
              <Form.Group as={Col} controlId="realtorZip">
                <Form.Label>Price</Form.Label>
                <Form.Control type="number" placeholder="$100000" onChange={(event) => {
                  setPrice(event.target.value);
                }} />
              </Form.Group>
              </Col>
            </Row>
            <Form.Group>
              <Form.Label>Upload Property Picture</Form.Label>
              <br></br>
              <input type="file" accept='image/png, image/jpeg' onChange={handleChange} />
            </Form.Group>
            <br></br>
            <Row>
              <Col lg='4'>
                <Button variant="primary" onClick={addProperty}>
                  Add Property
                </Button>
              </Col>
              <Col lg='4'>
                <Button variant="primary" onClick={goBack}>
                  Go Back
                </Button>
              </Col>
            </Row>
            <br></br>
          </Form>
        </Row>
        <br></br>
      </div>
    </Container>
  );

}
