import React, { useState } from "react";
import { Col, Form, Row, Button, Container } from 'react-bootstrap'
import { db } from '../firebase-config';
import { addDoc, collection } from 'firebase/firestore';
import { useNavigate } from "react-router-dom";

export default function Contact() {
    const [email, setEmail] = useState("");
    const [name, setName] = useState("");
    const [message, setMessage] = useState("");
    const formCollections = collection(db, "restatePropsContact");
    const history = useNavigate();

    const addFormEntry = async () => {
        await addDoc(formCollections, {
            name: name,
            email: email,
            message: message
        });
        history('/');
    };

    return (
        <Container>
        <Col>
            <Form>
                <Row>
                    <Col>
                        <Form.Label>Name</Form.Label>
                        <Form.Control type="text" placeholder="John Smith" onChange={(event) => {
                            setName(event.target.value);
                        }} />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Form.Label>Email</Form.Label>
                        <Form.Control type="text" placeholder="info@webdevry.com" onChange={(event) => {
                            setEmail(event.target.value);
                        }} />
                    </Col>
                </Row>

                <Row>
                    <Col>
                        <Form.Group className="mb-3" controlId="formBasicInvite">
                            <Form.Label>Message</Form.Label>
                            <Form.Control as={'textarea'} rows={3} onChange={(event) => {
                                setMessage(event.target.value);
                            }} />
                        </Form.Group>
                        <div
                            className="Form"
                            style={{ display: "flex", justifyContent: "center" }}
                        >
                            <Button variant="primary" onClick={addFormEntry}>
                                Submit
                            </Button>
                        </div>
                    </Col>
                </Row>
            </Form>
        </Col>
        </Container>
    );
}