import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import '../App.css';
import { auth, provider } from '../firebase-config'
import { signInWithPopup, GoogleAuthProvider } from 'firebase/auth';
import GoogleButton from 'react-google-button'

function Login() {

    const history = useNavigate();

    function checkSignedIn() {
    if (auth.currentUser) {
        return history('/dashboard');
      }
    }

    useEffect(() => {
        checkSignedIn();
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    const popup = () =>{
        signInWithPopup(auth, provider)
        .then((result) => {
          const credential = GoogleAuthProvider.credentialFromResult(result);
          // eslint-disable-next-line
          const token = credential.accessToken;

          history('/dashboard');
        }).catch((error) => {
          const errorCode = error.code;
          const errorMessage = error.message;
          const email = error.email;
          const credential = GoogleAuthProvider.credentialFromError(error);
          console.log(errorCode, " ", errorMessage, " email used: ", email, " cred: ", credential);

        });

    }

 

    return (
        <>
            <h1>Log In</h1>
            <div>
                <GoogleButton className="button" onClick={popup}>Sign in with google</GoogleButton>
            </div>
        </>
    );
};

export default Login;