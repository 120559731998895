import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import { getStorage } from 'firebase/storage';
import { getAuth, GoogleAuthProvider } from 'firebase/auth';
import { getAnalytics } from 'firebase/analytics'

const firebaseConfig = {
  apiKey: "AIzaSyCDrJol9C5k1nZX061BgpuoWJVoDQyxj-E",
  authDomain: "webdevry.com",
  databaseURL: "https://webdevry-default-rtdb.firebaseio.com",
  projectId: "webdevry",
  storageBucket: "webdevry.com",
  messagingSenderId: "1079061814862",
  appId: "1:1079061814862:web:f248be550ced1fec88ed1f",
  measurementId: "G-JPCB9J2T0J"
  };
  
const app = initializeApp(firebaseConfig);

export const auth = getAuth(app);
export const db = getFirestore(app);
export const storage = getStorage(app);
export const analytics = getAnalytics(app);
export const provider = new GoogleAuthProvider();
